import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalService } from './modules/library/services/global.service';
import { MessageService } from 'primeng/api';
import { JwtModule, JwtHelperService } from "@auth0/angular-jwt";
import { BaseInterceptor } from './common/base.interceptor';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => sessionStorage.getItem('id_token')
      }
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      isolate: true,
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),

  ],
  providers: [
    GlobalService,
    MessageService,
    { provide: HTTP_INTERCEPTORS, useClass: BaseInterceptor, multi: true }, JwtHelperService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
