import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { catchError, Observable, throwError, filter } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class BaseInterceptor implements HttpInterceptor {
  currentRoute = '';
  constructor(private router: Router) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentRoute = event.url;
      });
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = sessionStorage.getItem('accesstoken');
    const admintoken = sessionStorage.getItem('tokenAuth');
    let splitURL = this.currentRoute.toString().split('/');

    if (token && splitURL[1] !== 'admin') {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    if (admintoken && splitURL[1] === 'admin') {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${admintoken}`,
        },
      });
    }
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          this.router.navigate(['/unauthorized']);
          sessionStorage.removeItem('accesstoken');
          sessionStorage.removeItem('client_ip');
          sessionStorage.removeItem('session_id');
          sessionStorage.removeItem('tokenAuth');
        }
        const error = err.message || err.statusText;
        return throwError(() => error);
      })
    );
  }
}
