import { Component } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { GlobalService } from './modules/library/services/global.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'ChiltonLibrary';
  currentVehicle: any;
  subscription: any;
  token: any;
  decodedToken: any;
  url: any;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public titleService: Title,
    public globalService: GlobalService,
    private jwtHelper: JwtHelperService,
    private meta: Meta
  ) {
    this.getSearchedVehicle();
  }
  ngOnInit() {
    this.url = window.location;
    this.getTitle();
    this.authenticateToken();
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Get the detailed information you need to tackle vehicle maintenance and repairs',
      },
      {
        name: 'keywords',
        content:
          'chilton, library, vehicle, year, make, model, readspeaker, repair, estimate',
      },
    ]);
    const keywords = this.meta.getTag('name=keywords');
  }

  // to get access token
  authenticateToken() {
    if (this.url.search !== '' && this.url.pathname.split('/')[1] !== 'admin') {
      this.token = this.url.search.split('?id_token=')[1];
      sessionStorage.setItem('accesstoken', this.token);
      this.decodedToken = this.jwtHelper.decodeToken(this.token);
      sessionStorage.setItem('client_ip', this.decodedToken.client_ip);
      sessionStorage.setItem('location_id', this.decodedToken.location_id);
      sessionStorage.setItem('session_id', this.decodedToken.session_id);
      sessionStorage.setItem(
        'institution_id',
        this.decodedToken.institution_id
      );
      this.globalService.saveToken(this.token).subscribe((res) => {});
    }
  }

  getChild(activatedRoute: any): any {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  getSearchedVehicle(): any {
    this.globalService.getSearchedVehicle().subscribe((res: any) => {
      this.currentVehicle = res;
    });
  }

  // Title update
  getTitle() {
    this.subscription = this.globalService.getChangeVehicle();
    this.subscription.subscribe(() => {
      this.updateTitle();
    });
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updateTitle();
      });
  }

  updateTitle() {
    const rt = this.getChild(this.activatedRoute);
    rt.data.subscribe((data: any) => {
      const title =
        data.title == 'Repair content' ||
        data.title == 'Maintenance Charts' ||
        data.title == 'Labor Time Estimating' ||
        data.title == 'Technical Service Bulletins'
          ? data.title +
            ' ' +
            'for' +
            ' ' +
            this.currentVehicle.year.year +
            ' ' +
            this.currentVehicle.make.make +
            ' ' +
            this.currentVehicle.model.model
          : data.title;
      this.titleService.setTitle(title);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
