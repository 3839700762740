export const constObj = {
  // customer Reports
  searchManagement: {
    // getYear:'assets/year.json', //'year'
    // getMake: 'assets/make.json', //'make'
    // getModel: 'assets/model.json',  //'model'
    // getRecent: 'assets/recentVehicle.json',
    //getSubMenuList: 'assets/subMenuList.json',
    // getBaseVehicle: 'assets/baseVehicle.json',
    getYear: 'chilton-vehicle-service/year',
    getMake: 'chilton-vehicle-service/make/',
    getModel: 'chilton-vehicle-service/model/',
    getRecent: 'chilton-selectedvehicle-service/user',
    getSubMenu: 'assets/subMenu.json',
    getSubMenuList: 'chilton-menu-service/menu/',
    getBaseVehicle: 'chilton-vehicle-service/basevehicle',
    printAudit: 'chilton-vehicle-service/print/audit',
  },
  maintenanceManagement: {
    // milegeGetValue: 'assets/milegeValue.json',
    // milegeGetInformation: 'assets/serviceInformation.json',
    // nonCompact: "assets/NonCompact.json"
    // nonCompact: "assets/ArticleNonCompact.json",
    // viewFullChart:"assets/ViewFullChart.json",
    // getsubModel: 'assets/submodel.json',
    // getLiters: 'assets/liters.json',
    // getFuelType: 'assets/fuelType.json',
    // getCyliners: 'assets/cylinders.json',
    // getDriveType: 'assets/driveType.json',
    // getBodyType: 'assets/bodyType.json',
    // getTransmission: 'assets/transmission.json',
    // getOpCondition: 'assets/opCondition.json',
    // getFullChart: 'assets/fullchart.json',
    getSubModel: 'chilton-maintenance-service/submodel',
    getLiters: 'chilton-maintenance-service/liter',
    getFuelType: 'chilton-maintenance-service/fueltype',
    getCyliners: 'chilton-maintenance-service/cylinders',
    getDriveType: 'chilton-maintenance-service/drivetype',
    getBodyType: 'chilton-maintenance-service/bodytype',
    getTransmission: 'chilton-maintenance-service/transmission',
    getOpCondition: 'chilton-maintenance-service/operatingcondition',
    getFullChart: 'chilton-maintenance-service/fullchartlist',
    milegeGetValue: 'chilton-maintenance-service/milages',
    milegeGetInformation: 'chilton-maintenance-service/viewservice',
    milegeRecommendedInfo: 'chilton-maintenance-service/recommendation',
    viewFullChart: 'chilton-maintenance-service/viewfullchart',
  },

  repairManagement: {
    //tableOfContent: 'assets/tableTree.json',
    // getSpecialToolsNote: 'assets/repairNote.json',
    xmlFile: 'assets/recent.xml',
    feedback: 'chilton-util-service/feedback/save',
    rating: 'chilton-util-service/rating/save',

    tableOfContent: 'chilton-repair-service/toc/',
    getSpecialToolsNote: 'chilton-repair-service/toc/article',
    articleList: 'chilton-repair-service/toc/articlelist/',
    repairAudit: 'chilton-repair-service/toc/article/systemview/',
  },

  VINSearch: {
    recalls: 'chilton-bulletin-service/tsb/vehicle/details',
    // recalls: 'assets/recalls.json'
  },

  bulletinsManagement: {
    // bulletinsList: 'assets/bulletins.json',
    // systems: 'assets/systems.json',
    // symptoms: 'assets/symptoms.json',
    // troubleCode: 'assets/troubles.json',
    // tsbSearch: 'assets/tsbList.json',
    // advanceSearch: 'assets/advanceSearch.json',

    bulletinsList: 'chilton-bulletin-service/tsb/',
    systems: 'chilton-bulletin-service/tsb/systems/',
    symptoms: 'chilton-bulletin-service/tsb/symptoms/',
    troubleCode: 'chilton-bulletin-service/tsb/troublecode/',
    troubleCodeFilter: 'chilton-bulletin-service/tsb/troublecode/',
    tsbSearch: 'chilton-bulletin-service/tsb/v1',
    advanceSearch: 'chilton-bulletin-service/tsb/advance/search/',
    getAllList: 'chilton-bulletin-service/tsb/list/',
    getAllAdvanceList: 'chilton-bulletin-service/tsb/advance/search/print',
  },
  testprepQuiz: {
    questions: 'assets/testprepQuiz.json',
  },

  laborEstimate: {
    system: 'chilton-labor-service/estimating/systems/v1',
    subSystem: 'chilton-labor-service/estimating/subSystems/',
    searchOperation: 'chilton-labor-service/estimating/searchOperation/',
    searchTimes: 'chilton-labor-service/estimating/operations/',

    // tableOfContent: 'assets/laborTree.json',
    // searchOperation: 'assets/searchOperation.json',
    // searchTimes: 'assets/laborTimes.json'
  },
  help: {
    submit: 'chilton-util-service/askanexpert/save',
  },
  videoLibrary: {
    tableOfContent: 'chilton-content-service/videolibrary/tableofcontent',
    videos: 'chilton-content-service/videolibrary/videos/',
    animations: 'chilton-content-service/videolibrary/animation/',
    popularVideos: 'chilton-content-service/videolibrary/mostpopular/video',
    auditVideo: 'chilton-content-service/videolibrary/auditvideo',
  },
  saveAuthToken: {
    auth: 'chilton-security-service/auth',
  },
  admin: {
    login: 'chilton-security-service/authenticate',
    resetLink: 'chilton-security-service/forgotpassword',
    changePassword: 'chilton-security-service/resetpassword',
    register: 'chilton-security-service/register',
    auditReport: 'chilton-report-service/auditreport',
    mailReport: 'chilton-report-service/emailreport',
    missingReport:'chilton-report-service/imagereport',
    vehiclelist : 'chilton-report-service/vehiclename',
    missingImageCount:'chilton-report-service/missingimagecount',
    account: {
      userlist: 'chilton-security-service/userlist/',
      addUser: 'chilton-security-service/adduser',
      teamsList: 'chilton-security-service/teams',
      updateUser: 'chilton-security-service/updateuser',
      deleteUser: 'chilton-security-service/deleteuser',
      updatePassword: 'chilton-security-service/resetpassword',
    },
  },
};
